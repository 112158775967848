<template>
  <div class="Pagination">
    <div
      :style="
        paginationList.canClickOnPrevButton
          ? ''
          : 'cursor: default; opacity: 0.45;'
      "
      class="PBPrev"
      @click="
        () => {
          if (paginationList.canClickOnPrevButton) {
            page -= 1;
            pageUpdated();
          }
        }
      "
    >
      <div class="PBPrevIMG"></div>
    </div>

    <div class="PPagesList">
      <div
        v-for="(p, pindx) in paginationList.buttons"
        :key="`pag_${pindx}`"
        :class="`PBIndex noselect ${page === p.val ? 'PBActive' : ''}`"
        @click="
          () => {
            page = p.val;
            pageUpdated();
          }
        "
      >
        <span>{{ p.show_three_dots ? "..." : p.val }}</span>
      </div>
    </div>

    <div
      :style="
        paginationList.canClickOnNextButton
          ? ''
          : 'cursor: default; opacity: 0.45;'
      "
      class="PBNext"
      @click="
        () => {
          if (paginationList.canClickOnNextButton) {
            page += 1;
            pageUpdated();
          }
        }
      "
    >
      <div class="PBNextIMG"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationComponent",
  props: {
    clientMode: {
      type: Boolean,
      default: true,
    },

    dataCounter: {
      type: Number,
      default: 0,
    },

    //Сколько элементов отображаем из всего списка
    max_items: {
      type: Number,
      default: 6,
    },
    //Сколько кнопок с навигацией по "страницам" отображаем, включая текущую страницу
    max_pages_indexes: {
      type: Number,
      default: 3,
    },

    startPage: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      page: 1,
    };
  },
  created() {
    this.page = this.startPage;
  },
  computed: {
    paginationList() {
      let buttons_array = [];

      // Суммарное кол-во страниц
      let pagesCounter = Math.ceil(this.dataCounter / this.max_items);

      if (pagesCounter === 0) {
        pagesCounter = 1;
      }

      //Перечисление всех страниц 1, 2, 3 ... N
      let pagesList = this.countFromXToN(1, pagesCounter);

      //Индекс текущей страницы в массиве
      let currentPageIndex = this.page - 1;

      //Перечисление, какие страницы мы показываем в виде кнопок с цифрами
      let pagesButtonForShow;
      if (currentPageIndex === 0) {
        pagesButtonForShow = pagesList.slice(
          currentPageIndex,
          currentPageIndex + this.max_pages_indexes
        );
      } else {
        pagesButtonForShow = pagesList.slice(
          currentPageIndex - 1,
          currentPageIndex - 1 + this.max_pages_indexes
        );
      }

      let canClickOnPrevButton = this.page != 1;
      let canClickOnNextButton = this.page != pagesCounter;

      //Разница между последней отображаемой кнопкой и последней страницей
      let difference =
        pagesCounter - pagesButtonForShow[pagesButtonForShow.length - 1];

      //Чтобы показывать 3 точки и последнюю страницу, разница должна быть больше 2
      let show3DotsAndLastElement = difference > 2;

      //Подготавливаем
      for (let pageNumber of pagesButtonForShow) {
        buttons_array.push({
          show_three_dots: false, //true / false
          val: pageNumber,
        });
      }

      if (show3DotsAndLastElement) {
        buttons_array.push({
          show_three_dots: true,
          val:
            pagesButtonForShow[pagesButtonForShow.length - 1] +
            Math.ceil(difference / 2),
        });
        buttons_array.push({
          show_three_dots: false,
          val: pagesCounter,
        });
      } else {
        //Показываем оставшиеся страницы
        if (
          !pagesButtonForShow.includes(pagesCounter - 1) &&
          currentPageIndex < pagesCounter - 1
        ) {
          buttons_array.push({
            show_three_dots: false, //true / false
            val: pagesCounter - 1,
          });
        }

        if (!pagesButtonForShow.includes(pagesCounter)) {
          buttons_array.push({
            show_three_dots: false, //true / false
            val: pagesCounter,
          });
        }

        if (buttons_array.length < this.max_pages_indexes + 2) {
          let startIndex = buttons_array[0].val - 1;
          let pages = this.countFromXToN(
            1,
            this.max_pages_indexes + 2 - buttons_array.length
          );

          for (let i of pages) {
            let __page = startIndex - i + 1;
            if (__page >= 1) {
              buttons_array.unshift({
                show_three_dots: false,
                val: __page,
              });
            }
          }
        }
      }

      return {
        canClickOnPrevButton: canClickOnPrevButton,
        canClickOnNextButton: canClickOnNextButton,
        buttons: buttons_array,
      };
    },
  },
  methods: {
    countFromXToN(x, n) {
      let res = [];
      if (x <= n) {
        for (let i = x; i <= n; i++) {
          res.push(i);
        }
      } else {
        for (let i = x; i >= n; i--) {
          res.push(i);
        }
      }
      return res;
    },

    pageUpdated() {
      this.$emit("update:page", this.page);
    },
  },
};
</script>

<style scoped>
.Pagination {
  position: relative;
  display: flex;

  margin: 0% auto;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;
}

.PPagesList {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  flex-shrink: 1;
  flex-grow: 0;
}

.PBPrev,
.PBNext,
.PBIndex {
  position: relative;
  display: flex;

  cursor: pointer;

  width: 52px;
  height: 52px;

  transition: 0.25s;

  border-radius: var(--o-s-global-border-radius);
  background-color: #423b8b;
}
.PBIndex.PBActive {
  background-color: #8f63f0;
}
.PBIndex > span {
  position: relative;
  display: flex;

  margin: auto;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: #e9e9ee;
}

.PBPrevIMG,
.PBNextIMG {
  position: relative;
  display: block;

  width: 100%;
  height: 100%;

  background: url("./../../assets/img/RatingStudent/arrow_back.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
}
.PBNextIMG {
  rotate: 180deg;
}

@media (max-width: 992px) {
  .Pagination {
    margin: 0% auto;

    width: fit-content;
    max-width: 100%;

    gap: 8px;

    overflow: hidden;
  }
  .Pagination > *:not(.PPagesList) {
    flex-shrink: 0;
    flex-grow: 0;
  }

  .PPagesList {
    flex-grow: 0;
    overflow: auto;
  }
  .PBIndex {
    flex-shrink: 0;
  }

  .PBPrev,
  .PBNext,
  .PBIndex {
    width: 30px;
    height: 30px;
    border-radius: 8px;
  }
}
</style>
