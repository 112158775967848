<template>
  <OneColumnTemplate :isOrgSidebar="true" class="Restyle">
    <template v-slot:ColumnContent>
      <div id="RatingPage">
        <div v-if="!pageIsLoading" class="container">
          <div class="header">
            <router-link
              class="button secondary btn_icon"
              to="/org/students-list"
            >
              <div class="img24" id="img-btn-back"></div>
            </router-link>
          </div>

          <div class="student-info">
            <div class="student-header">
              <div class="student">
                <span class="heading_1" id="student-name">
                  {{ getAnonFIO(userData.user.name, userData.user.surname) }}
                </span>
                <span class="title_large" id="student-educational">
                  {{
                    getUserRegionAndUniver == null
                      ? `ВУЗ не указан`
                      : `обучающийся в ${getUserRegionAndUniver}`
                  }}
                </span>
                <div class="student-discription">
                  <span class="title_large status"
                    >{{ getTextForStudentInfo() }}
                  </span>
                </div>
              </div>
              <!-- <div class="rating">
                <span class="heading_2" id="rating-number">4.51</span>
                <span class="title_medium">Рейтинг<br />на окатгоне</span>
              </div> -->
            </div>

            <!-- <div class="about-student">
              <span class="heading_2  header">
                Факт о выпускнике
              </span>
              <div class="title_large about-student-discription">
                В Японии с выпускными все довольно строго. Выпускники надевают
                повседневную школьную форму, никаких блестящих платьев,
                смокингов и так далее. Только школьная форма и поздравление от
                директора и учителей.
              </div>
            </div> -->

            <!-- <div class="button  primary btn_gitHub btn_gitHub__student">
              <span class="text-btn">Посмотреть проекты в GitHub</span>
            </div> -->

            <!-- <div class="box review-box">
              <span class="heading_2">Отзыв наставника</span>
              <div class="review">
                <span class="title_medium" id="skill">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been he industry's
                </span>
              </div>
            </div> -->

            <div class="background-img"></div>
          </div>

          <div v-if="showDirectionID != null" class="comnpetation">
            <span class="heading_1  header-competation">
              Были получены компетенции
            </span>
            <div class="competation-skills-box">
              <div>
                <div class="headings_filter">
                  <div
                    v-for="(d, dindx) in directionsFromUser"
                    :key="`d_-${dindx}-${d.name}`"
                    :class="
                      `competation-header heading_2   btn__heading_2 ${
                        showDirectionID === d.id ? 'activate' : ''
                      }`
                    "
                    @click="showDirectionID = d.id"
                  >
                    <span class="btn_filter">{{ d.name }}</span>
                  </div>
                </div>
                <div class="box competation-skills">
                  <div
                    v-for="(l, lindx) in getLevelsForDirectionID"
                    :key="`lvl_${lindx}_${l.id}_${l.name}`"
                    class="skill"
                  >
                    <div class="competation_level">
                      <div class="img24" id="img-complete"></div>
                      <span class="title_medium level"
                        >{{ l.level }} уровень компетенции</span
                      >
                    </div>
                    <span class="title_medium skill-level">{{ l.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="userData.jobs_completed.length" class="tasks">
            <span class="TasksHeader"
              >Выпускник решил задач{{
                userData.jobs_completed.length == 1 ? "у" : "и"
              }}</span
            >
            <div class="TasksList">
              <div
                v-for="(j, jindx) in userData.jobs_completed"
                :key="`job_${jindx}`"
                class="TLCard"
              >
                <span class="TLCJobName AutoHyphens" lang="ru">{{
                  j.name
                }}</span>

                <div class="TLCAddInfo">
                  <span>Сложность задачи</span>
                  <div class="ProjectDifficult">
                    <div
                      v-for="index in getTaskDifficult(j?.level)"
                      :key="index"
                      class="DifficultPainted"
                    />
                    <div
                      v-for="index in 3 - getTaskDifficult(j?.level)"
                      :key="index"
                      class="DifficultEmpty"
                    />
                  </div>
                  <span
                    >Задача относится к проекту «{{ getProjectName(j) }}»</span
                  >
                </div>

                <details class="TLCDetails">
                  <summary class="TLCDHead noselect">
                    <span>Описание задачи</span>
                    <div class="TLCDHArrow"></div>
                  </summary>
                  <div class="TLCDBody ql-snow">
                    <span class="ql-editor" v-html="j.jobDesc"></span>
                  </div>
                </details>

                <details v-if="j.ready_comment != null" class="TLCDetails">
                  <summary class="TLCDHead noselect">
                    <span>Отзыв наставника</span>
                    <div class="TLCDHArrow"></div>
                  </summary>
                  <div class="TLCDBody ql-snow">
                    <span class="ql-editor" v-html="j.ready_comment"></span>
                  </div>
                </details>

                <a
                  v-if="j.ready_link != null"
                  class="TLCGithub"
                  :href="j.ready_link"
                  >Перейти к решению задачи
                  <div class="SRLinkIMG"></div
                ></a>
              </div>
            </div>
          </div>
        </div>

        <div v-show="pageIsLoading" class="page-loader-wrapper">
          <div class="lds-dual-ring page-loader"></div>
        </div>
      </div>
    </template>
  </OneColumnTemplate>
</template>

<script>
import OneColumnTemplate from "@/components/ProfileTemplates/OneColumn.vue";

import { getUserInfoForRating, getUserCells } from "@/api/user.js";
import { getSections } from "@/api/competence.js";
export default {
  data() {
    return {
      watchUserID: null,
      userData: null,
      directionsRaw: [],
      directionsFromUser: [],
      userCells: [],

      notRedirect: false,
      pageIsLoading: true,

      showDirectionID: null,
      selectedTask: null,
    };
  },
  name: "OrgRatingStudent",
  components: { OneColumnTemplate },
  async created() {
    this.notRedirect =
      this.$route.query?.show_errors != null &&
      this.$route.query?.show_errors === "true";

    try {
      let response = await getUserInfoForRating(this.$route.query?.user);
      this.userData = response.data;
      this.watchUserID = this.$route.query?.user;
    } catch (e) {
      this.errorRedirect("Получение инфы о пользователе", e);
      return 0;
    }

    try {
      let getSectionsResponse = await getSections();
      this.directionsRaw = getSectionsResponse.data.map((direction) => {
        let levelsSorted = [];

        /*
          ^Взято из client/src/views/Profile/About.vue, created() -> getSection
          *ChatGPT сделал подсказки по данному коду
        */
        let countL = 1; // Инициализируем счетчик для нумерации уровней

        // Сортируем массив degrees по убыванию id
        direction.degrees.sort((a, b) => b.id - a.id);

        // Проходим по каждому элементу массива degrees
        for (let i = 0; i < direction.degrees.length; i++) {
          // Сортируем массив levels для каждой degree по возрастанию id
          direction.degrees[i].levels.sort((a, b) => a.id - b.id);

          // Проходим по каждому уровню в текущей degree
          for (let j = 0; j < direction.degrees[i].levels.length; j++) {
            // Добавляем уровень в массив levelsSorted и присваиваем ему номер countL
            levelsSorted.push(
              Object.assign(direction.degrees[i].levels[j], {
                level: countL, // Присваиваем номер уровня
              })
            );

            countL++; // Увеличиваем счетчик для следующего уровня
          }
        }
        //Взято из client/src/views/Profile/About.vue, created() -> getSection

        return { id: direction.id, name: direction.name, levels: levelsSorted };
      });
    } catch (e) {
      this.errorRedirect("Получение инфы о секциях", e);
      return 0;
    }

    try {
      let getUserCellsResponse = await getUserCells(this.watchUserID);
      this.userCells = getUserCellsResponse.data?.user_cells.map(
        (x) => x.level_id
      );
    } catch (e) {
      this.errorRedirect("Получение инфы о пользователе (уровни обучения)", e);
      return 0;
    }

    this.directionsFromUser = this.getUsersSections();
    this.showDirectionID = this.directionsFromUser?.[0]?.["id"];

    this.selectedTask = this.userData.jobs_completed[0];

    this.pageIsLoading = false;
  },
  computed: {
    getUserRegionAndUniver() {
      if (
        this.userData?.user?.MapRegion == null ||
        this.userData.user.MapRegion.univer_id == null
      ) {
        return null;
      }

      if (this.userData.user.MapRegion.UsersChoosedUniver.name === "Другое") {
        return this.userData.user.MapRegion.univer_other;
      } else {
        return this.userData.user.MapRegion.UsersChoosedUniver.name;
      }
    },

    getLevelsForDirectionID() {
      if (this.showDirectionID == null) {
        return [];
      }

      let findDirection = this.directionsFromUser.find(
        (x) => x.id === this.showDirectionID
      );

      return findDirection.levels;
    },

    showGitButton() {
      if (
        this.userData?.user?.git == null ||
        this.userData.user.git === "" ||
        !this.userData.user.git.includes("github.com")
      ) {
        return false;
      }

      return true;
    },

    getSelectedTaskInfo() {
      let name =
        this.selectedTask.nProjectId == null
          ? this.selectedTask.projectName
          : this.selectedTask.nProjectName;
      let desc =
        this.selectedTask.nProjectId == null
          ? this.selectedTask.projectDescription
          : this.selectedTask.nProjectDescription;

      return {
        name: name,
        desc: desc,
      };
    },
  },
  methods: {
    errorRedirect(from, e) {
      if (this.notRedirect) {
        console.log(from);
        console.log(e);
        return 0;
      }

      this.$router.push({ name: "OrgGetStudentsList" });
    },

    getAnonFIO(name, surname) {
      name = name == null ? "" : name.trim();
      surname = surname == null ? "" : surname.trim();
      if (name !== "" && surname !== "") {
        return `${name} ${surname.charAt(0).toUpperCase()}.`;
      }
      if (name !== "" || surname !== "") {
        return name !== "" ? name : `${surname.charAt(0).toUpperCase()}.`;
      }
      return "Не указано";
    },

    getAnonFIO(name, surname) {
      name = name == null ? "" : name.trim();
      surname = surname == null ? "" : surname.trim();
      if (name !== "" && surname !== "") {
        return `${name} ${surname.charAt(0).toUpperCase()}.`;
      }
      if (name !== "" || surname !== "") {
        return name !== "" ? name : `${surname.charAt(0).toUpperCase()}.`;
      }
      return "Не указано";
    },

    getUsersSections() {
      if (this.directionsRaw.length == 0 || this.userCells.length == 0) {
        return [];
      }

      return this.directionsRaw
        .map((_direction) => {
          let levelsReady = _direction.levels.filter((_lvl) =>
            this.userCells.includes(_lvl.id)
          );

          if (levelsReady.length === 0) {
            return null;
          }

          return {
            id: _direction.id,
            name: _direction.name,
            levels: levelsReady,
          };
        })
        .filter((x) => x != null);
    },

    getUserDirectionsText() {
      return this.directionsFromUser.map((x) => x.name).join(", ");
    },
    getUserTasksCompleted() {
      return {
        text: this.userData.jobs_completed.map((x) => `«${x.name}»`).join(", "),
        count: this.userData.jobs_completed.length,
      };
    },

    getProjectName(j) {
      return j.nProjectId == null ? j.projectName : j.nProjectName;
    },

    getTaskDifficult(DiffName) {
      switch (DiffName) {
        case "Легкий": {
          return 1;
        }
        case "Средний": {
          return 2;
        }
        case "Сложный": {
          return 3;
        }
      }
    },

    getTextForStudentInfo() {
      let hasCompet = this.directionsFromUser.length != 0;
      let hasTasks = this.getUserTasksCompleted().count != 0;

      let competText = `Успешно освоил компетенци${
        this.directionsFromUser.length === 1 ? "ю" : "и"
      }: ${this.getUserDirectionsText()}.`;

      let tasksText = `Реализовал задач${
        this.getUserTasksCompleted().count === 1 ? "у" : "и"
      }: ${this.getUserTasksCompleted().text}.`;

      if (hasCompet && hasTasks) {
        return `${competText} ${tasksText}`;
      }

      if (hasCompet || hasTasks) {
        return hasCompet ? competText : tasksText;
      }

      return "Студент ещё не проявил никакой активности на платформе";
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;
}
.competation-header {
  position: relative;
  width: fit-content;
  flex-shrink: 0;
}

#RatingPage {
  background: #232659;
  position: relative;
  width: 100%;
  min-height: 100%;
  height: fit-content;

  top: 0%;
  left: 0%;

  margin: 0%;
  padding: 0%;

  overflow: hidden;
}

template {
  font-family: "Montserrat";
  font-optical-sizing: auto;
  background-color: #232659 !important;
}

.button {
  border: none;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  box-shadow: none;
}

.text-btn {
  user-select: none;
  pointer-events: none;
}

.img24 {
  width: 24px;
  height: 24px;
}

.heading_1 {
  font-family: "Montserrat";
  color: rgb(255, 255, 255);
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  z-index: 1;
}

.heading_2 {
  font-family: "Montserrat";
  color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 24px;
  /* line-height: 32px; */
  letter-spacing: 0.5px;
}

.btn__heading_2 {
  font-family: "Montserrat";
  color: #665fae;
  font-weight: 500;
  font-size: 24px;
  /* line-height: 32px; */
  letter-spacing: 0.5px;
}

.btn_filter {
  pointer-events: none;
}

.btn__heading_2:hover {
  color: #5445a4;
  transition: 0.2s;
}

.heading_3 {
  font-family: "Montserrat";
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  flex-shrink: 0;
}

.title_large {
  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: rgba(255, 255, 255, 0.9);
}

.title_medium {
  font-family: "Montserrat";
  font-size: 16px;
  letter-spacing: 0.5px;
  color: rgb(255, 255, 255);
}

.title_small {
  font-size: 14px;
  letter-spacing: 1px;
  color: rgba(255, 255, 255, 0.9);
}

.student-header {
  align-items: center;
}

.student-discription {
  width: 80%;
}

.skills-list {
  display: flex;
  flex-direction: row;
  gap: 2rem;
}

#btn-text {
  display: none;
}

/* btn  secondary */

.secondary {
  padding: 1.25rem;
  background-color: #423b8b;
  color: rgba(255, 255, 255, 0.9);
  width: max-content;
}

.secondary:hover {
  background-color: #5445a4;
  transition: 0.2s;
}

.secondary:active {
  background-color: #423b8b;
}

.btn_icon {
  padding: 0;
  height: 52px;
  width: 52px;
}

.box {
  border-radius: 2rem;
  width: 100%;
  margin-top: 1.25rem;
  flex-wrap: wrap;
  background-color: #232659;
}

/* btn  primary */

.primary {
  margin-top: 2rem;
  height: 56px;
  padding: 0 32px;
  width: max-content;
  background: linear-gradient(to right, #8f63f0, #ba4bb5);
  color: rgba(255, 255, 255, 0.9);
}

.primary:hover {
  background-color: #7b58d5;
  transition: 0.2s;
}

.primary:active {
  background-color: #8f63f0;
  transition: 0.2s;
}

#img-btn-back {
  background: url("./../../assets/img/RatingStudent/arrow_back.svg");
}

/* student-header */

.student-info {
  margin-top: 32px;
}

.student {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.student-header {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
}

/* .student-header {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: flex-start;

} */

#student-educational {
  color: rgba(255, 255, 255, 0.3);
  margin-top: 0.05rem;
  font-family: "Montserrat";
}

.rating {
  border-radius: 32px;
  padding: 24px;
  display: flex;
  background-color: rgba(255, 255, 255, 0.1);
  gap: 4px;
  color: rgba(255, 255, 255, 0.9);
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  width: max-content;
}

.review-box {
  position: relative;
  border-radius: 32px;
  padding: 32px;
  width: 100%;
  background-color: rgba(42, 46, 107, 0.6);
  z-index: 1;
}

#rating-number {
  font-size: 3rem;
}

.student-discription {
  color: rgba(255, 255, 255, 0.9);
  margin-top: 0.5rem;
}

.about-student {
  margin-top: 2rem;
}

.about-student-discription {
  margin-top: 1.25rem;
  color: rgba(255, 255, 255, 0.9);
}

.student-info > *:not(.background-img) {
  z-index: 1;
  position: relative;
}

.background-img {
  position: absolute;
  overflow: visible;
  display: block;
  pointer-events: none;
  user-select: none;
  width: 100%;
  height: 100%;
  background: url("./../../assets/img/ReDesign/pages/rating_student/bg_light.png");
  background-repeat: no-repeat;
  z-index: 0;
  top: 0;
  left: 0;
  background-size: contain;
  filter: blur(25px);
  opacity: 0.4;
}

/* comnpetation */
.comnpetation {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 100px;
}

.header-competation {
  text-align: center;
}

.competation-skills {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-width: 100%;
}

.skill {
  padding: 32px;
  border-radius: 32px;
  background-color: rgba(42, 46, 107, 0.6);
  width: 380px;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.competation_level {
  color: #5fcbd7;
  display: flex;
  gap: 8px;
}

.headings_filter {
  display: flex;
  gap: 32px;
  overflow-x: auto;
  padding-bottom: 8px;
}

.activate {
  color: rgb(255, 255, 255);
}

.skills-list {
  margin-top: 1.5rem;
}

#img-complete {
  background: url("./../../assets/img/RatingStudent/complete.svg");
  background-position: center;
  /* Позиционирование изображения */
  background-repeat: no-repeat;
  /* Запрет повторения изображения */
}

.skill-content {
  position: relative;
  display: flex;

  cursor: pointer;

  padding: 12px 16px;

  height: max-content;
  flex-shrink: 0;

  transition: 0.25s;

  border-radius: var(--o-s-global-border-radius);
  background-color: #423b8b;
}
.skill-content.Active {
  background-color: #8f63f0;
}

.task {
  width: 90%;
}

.skills-left,
.skills-right {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

#img-btn-down {
  background: url("./../../assets/img/RatingStudent/arrow-down.svg");
}

.skills-scroll::-webkit-scrollbar {
  width: 8px;
  /* Ширина скролла */
}

/* Стиль для ползунка прокрутки (scroll thumb) */
.skills-scroll::-webkit-scrollbar-thumb {
  background: #232659;
  /* Цвет ползунка */
  border-radius: 4px;
  /* Закругление углов */
}

/* Стиль для фона полосы прокрутки */
.skills-scroll::-webkit-scrollbar-track {
  opacity: 0;
  /* Цвет фона скролла */
}

/* Стиль при наведении на ползунок */
.skills-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #5445a4;
  /* Более темный цвет при наведении */
}

/* task */
.tasks {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.task {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  color: rgba(255, 255, 255, 0.9);
}

.tasks-info,
.tasks-points {
  background-color: rgba(42, 46, 107, 0.6);
  padding: 2rem;
  border-radius: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tasks-container {
  width: 100%;
}

.tasks-info,
.tasks-points {
  gap: 2rem;
}

.tasks-points {
  width: 100%;
}

.tasks-info {
  /* width: fit-content; */
  height: 100%;
}

.btn_gitHub {
  margin-top: 0;
}

.btn_gitHub__student {
  margin-top: 32px;
  width: 380px;
  align-items: center;
}

.tasks > *:not(.background-img-two) {
  z-index: 1;
  position: relative;
}

.background-img-two {
  position: absolute;
  overflow: visible;
  display: block;
  pointer-events: none;
  user-select: none;
  width: 100%;
  height: 100%;
  background: url("./../../assets/img/RatingStudent/background-two.svg");
  background-repeat: no-repeat;
  z-index: 0;
  left: 0;
  background-size: contain;
}

.skills-point,
.skills-scroll {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.skills-scroll {
  height: 450px;
  overflow-y: auto;
}

@media (max-width: 480px) {
  .container {
    margin: 0% auto;
    width: 100%;
    padding: 16px;
  }

  .competation-skills {
    justify-content: center;
  }

  .student-header {
    flex-direction: column;
  }

  .rating {
    margin-top: 24px;
    flex-direction: row;
  }

  .student-discription {
    width: 100%;
  }

  .competation-header {
    font-size: 16px;
    transition: 0.35s;
  }

  .task {
    width: fit-content;
  }

  .box {
    padding: 32px 16px;
  }

  .TLCDetails > .TLCDHead > span {
    font-size: 16px;
  }
  .TLCard > .TLCGithub {
    flex-direction: column-reverse;
    text-align: center;
    text-decoration: none;
    background-color: #423b8b2e;
    border-radius: var(--o-s-global-border-radius);
    padding: 8px;
  }
  .TLCGithub > .SRLinkIMG {
    margin: auto;
  }
}

@media (min-width: 480px) and (max-width: 768px) {
  .container {
    margin: 0% auto;
    width: 100%;
    padding: 16px;
  }

  .student-header {
    flex-direction: column;
  }

  .rating {
    margin-top: 24px;
    flex-direction: row;
  }

  .student-discription {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .container {
    margin: 0% auto;
    width: 100%;
    padding: 16px;
  }

  .student-header {
    flex-direction: column;
  }

  .rating {
    margin-top: 24px;
    flex-direction: row;
  }

  .student-discription {
    width: 100%;
  }
}

@media (max-width: 1300px) {
  .tasks {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
  }

  .competation-skills {
    justify-content: center;
  }
}

@media (min-width: 992px) and (max-width: 1600px) {
  .container {
    margin: 0% auto;
    width: 100%;
    padding: 40px;
  }

  .competation-skills {
    justify-content: center;
  }
}

@media (min-width: 1600px) {
  .container {
    margin: 0% auto;
    width: 1500px;
    padding: 40px;
  }
}

@media (max-width: 992px) {
  #RatingPage {
    margin-bottom: 100px;
  }
}
</style>

<style scoped>
.box.review-box {
  margin-top: 2rem;
}
.Restyle:deep(.ProfileContent) {
  overflow: hidden;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.Restyle:deep(.ProfileBlocks) {
  overflow: auto;
}
.Restyle {
  background: #232659 !important;
}
</style>

<style scoped>
.TasksHeader {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 36px;
  font-weight: 700;
  line-height: 44px;
  text-align: left;
  color: #ffffffe5;
}
.TasksList {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
}

.TLCard {
  position: relative;
  display: flex;

  padding: 32px;

  width: 100%;
  height: fit-content;

  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;

  background-color: rgba(41, 45, 104, 0.8);
  border-radius: 32px;
}
.TLCJobName {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;

  color: rgba(255, 255, 255, 0.9);
}
.TLCAddInfo > * {
  margin: auto 0%;
}
.TLCAddInfo {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.TLCAddInfo > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;

  color: rgba(255, 255, 255, 0.6);
}
.ProjectDifficult {
  position: relative;
  display: flex;

  margin-left: 8px;
  margin-right: 16px;

  width: auto;
  height: auto;

  flex-direction: row;
}
.ProjectDifficult > span:not(:nth-last-child(1)) {
  margin-right: 3px;
}
.ProjectDifficult > span {
  margin-top: auto;
  margin-bottom: auto;
}
.DifficultPainted {
  position: relative;
  display: block;
  width: 10px;
  height: 20px;
  background-image: url("./../../assets/img/profile/ProfileProjectCard/DifficultIcon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.DifficultEmpty {
  position: relative;
  display: block;
  width: 10px;
  height: 20px;
  background-image: url("./../../assets/img/profile/ProfileProjectCard/DifficultIconPlaceholder.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  filter: brightness(0) saturate(100%) invert(24%) sepia(8%) saturate(3135%)
    hue-rotate(199deg) brightness(89%) contrast(86%);
}

.TLCDetails {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: column;
}
.TLCDHead {
  position: relative;
  display: flex;

  padding: 12px 16px;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: space-between;

  background-color: rgb(66, 59, 139);
  border-radius: var(--o-s-global-border-radius);

  list-style: none; /* Убирает маркер или стрелку */
}
.TLCDHead::-webkit-details-marker {
  display: none; /* Скрывает стрелку в браузерах на основе WebKit (Chrome, Safari) */
}
.TLCDHead > * {
  margin: auto 0%;
}
.TLCDHead > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;

  color: rgba(255, 255, 255, 0.9);
}
.TLCDetails[open] .TLCDHArrow {
  rotate: 0deg;
}
.TLCDHArrow {
  position: relative;
  display: block;

  width: 24px;
  height: 24px;

  background: url("./../../assets/img/ReDesign/interface_icons/arrow_up.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  rotate: 180deg;
}

.TLCDBody {
  position: relative;
  display: flex;

  margin-top: 4px;
  padding: 16px;

  width: 100%;
  height: fit-content;

  background-color: rgb(35, 38, 89);
  border-radius: var(--o-s-global-border-radius);
}
.TLCDBody > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.9);
}

.TLCGithub {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.25px;
  text-align: left;
  text-decoration: underline;

  color: #ac88ff;
}
.SRLinkIMG {
  position: relative;
  display: block;

  width: 24px;
  height: 24px;

  background: url("./../../assets/img/ReDesign/interface_icons/arrow_link_24px.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;

  filter: invert(58%) sepia(55%) saturate(1939%) hue-rotate(215deg)
    brightness(100%) contrast(103%);
}

.AutoHyphens {
  hyphens: auto;
  word-wrap: anywhere;
}
</style>
