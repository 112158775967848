import request from "@/api/config.js";

export function update(id, data) {
  return request({
    url: `n-project/${id}`,
    method: "put",
    data,
  });
}

export function findByAuthorId(id) {
  return request({
    url: `n-project/by-author/${id}`,
    method: "get",
  });
}

export function findAll() {
  return request({
    url: `n-project`,
    method: "get",
  });
}

export function getApprovedProjectsWithTasks() {
  return request({
    url: `n-project/get/approved-projects-with-tasks`,
    method: "get",
  });
}

export function findById(id) {
  return request({
    url: `n-project/${id}`,
    method: "get",
  });
}
export function getNProjectWithTasks(id) {
  return request({
    timeout: 15000,
    url: `n-project/${id}/get/nproject-with-tasks`,
    method: "get",
  });
}

export function create(data) {
  return request({
    url: `n-project/create`,
    method: "post",
    data,
  });
}

export function deleteProject(id) {
  return request({
    url: `n-project/${id}`,
    method: "delete",
  });
}
