<template>
  <div
    :class="{
      Secondary: true,
      noselect: true,
      Disabled: !isActive,
      ...getSizeStyle,
    }"
    @click="clickEvent"
  >
    <slot name="LeftIcon"></slot>

    <span class="TText" lang="ru">{{ ButtonText }}</span>

    <slot name="RightIcon"></slot>
  </div>
</template>

<script>
/*
    Если вставляются какие-то иконки, им нужно прописать отдельно стили для цвета при разных состояниях

    .Secondary:not(.Disabled):active > .YourIconClass {
        color/fill: rgba(101, 107, 255, 1);
    }

    .Secondary.Disabled > .YourIconClass {
        color/fill: rgba(211, 202, 255, 0.3);
    }
*/
export default {
  name: "OUISecondaryButton",
  props: {
    ButtonText: {
      type: String,
      default: () => {
        return "";
      },
    },
    isActive: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    SendCallback: {
      type: Function,
      default: () => {
        return () => {};
      },
    },
    size: {
      type: String,
      default: "small",
    },
  },
  methods: {
    clickEvent() {
      if (this.isActive) {
        this.SendCallback();
      }
    },
  },
  computed: {
    getSizeStyle() {
      let styles = ["small", "medium"];
      if (styles.includes(this.size)) {
        let obj = {};
        obj[this.size] = true;
        return obj;
      }

      return { small: true };
    },
  },
};
</script>

<style scoped>
.Secondary.medium {
  padding: 14px 24px;
}
.medium > .TText {
  font-size: 16px;
}

.Secondary {
  position: relative;
  display: flex;

  cursor: pointer;

  padding: 8px;

  min-width: 0;
  width: fit-content;
  max-width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 4px;

  border-radius: 4px;

  background-color: #656bff;

  transition: 0.2s;
}
.Secondary > * {
  margin-top: auto;
  margin-bottom: auto;
}
.TText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  hyphens: auto;
  word-break: break-all;

  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;

  color: rgba(255, 255, 255, 0.9);
}

.Secondary:not(.Disabled):hover {
  background-color: #656bffe5;
}

.Secondary:not(.Disabled):active {
  background-color: #656bff80;
}
.Secondary:not(.Disabled):active > .TText {
  color: #ffffffe5;
}

.Secondary.Disabled {
  cursor: default;
  background-color: #656bff33;
}
.Secondary.Disabled > .TText {
  color: rgba(211, 202, 255, 0.3);
}

.Secondary.Disabled.AnimationIfNotActive {
  background: linear-gradient(130deg, #000, rgb(99, 52, 230), #000);
  background-size: auto;
  background-size: auto;
  background-size: 200% 200%;

  animation: notActiveAnim 3s cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
}

@keyframes notActiveAnim {
  0% {
    background-position: 10% 0%;
  }
  50% {
    background-position: 91% 100%;
  }
  100% {
    background-position: 10% 0%;
  }
}
</style>
