<template>
  <div :class="['SortWithFiltersPreset', onlySearch ? 'OnlySearchMode' : '']">
    <span class="FiltersHeader">{{ title }}</span>

    <slot name="content"></slot>

    <div class="Filters">
      <div class="FiltersBlock noselect">
        <div class="StatusesBlock">
          <span
            v-for="(s, sindx) in filtersButtons"
            :class="{
              selected: s.val === FilterSelected,
            }"
            :key="s + sindx"
            @contextmenu.prevent="handleRightClickOnButton(s.val)"
            @click="
              if (FilterSelected == s.val) {
                FilterSelected = null;
              } else {
                FilterSelected = s.val;

                postFiltersDataChanges({
                  button: FilterSelected,
                  search: SearchText,
                  sorting: SortSelected,
                });

                if (filterButtonModeServer) {
                  callbackForFilterButton(s.val);
                } else {
                  sortAndFilterArray();
                }
              }
            "
            >{{ s.text }}</span
          >
        </div>
        <div class="SortBy">
          <select
            v-if="sortOptions?.length > 1"
            class="SelectInput"
            v-model="SortSelected"
            @change="
              () => {
                postFiltersDataChanges({
                  button: FilterSelected,
                  search: SearchText,
                  sorting: SortSelected,
                });
                sortAndFilterArray();
              }
            "
          >
            <option
              v-for="(f, findx) in sortOptions"
              :key="f.text + findx"
              :value="f.val"
              >{{ f.text }}</option
            >
          </select>

          <span
            class="ResetFilters"
            @click="
              () => {
                resetFilters();
              }
            "
            >Сбросить фильтры</span
          >
        </div>
      </div>

      <input
        class="SearchInput"
        type="text"
        v-model="SearchText"
        :placeholder="searchPlaceholder"
        @input="
          () => {
            postFiltersDataChanges({
              button: FilterSelected,
              search: SearchText,
              sorting: SortSelected,
            });
            sortAndFilterArray();
          }
        "
      />

      <div class="MobileFilterBlock" @click="showMobileMenu = !showMobileMenu">
        <svg
          width="16"
          height="18"
          viewBox="0 0 16 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.0001 9V16.88C10.0401 17.18 9.94013 17.5 9.71013 17.71C9.61762 17.8027 9.50773 17.8762 9.38675 17.9264C9.26578 17.9766 9.1361 18.0024 9.00513 18.0024C8.87416 18.0024 8.74448 17.9766 8.62351 17.9264C8.50253 17.8762 8.39264 17.8027 8.30013 17.71L6.29013 15.7C6.18107 15.5934 6.09814 15.463 6.04783 15.319C5.99752 15.175 5.9812 15.0213 6.00013 14.87V9H5.97013L0.210131 1.62C0.0477393 1.41153 -0.0255351 1.14726 0.00631899 0.88493C0.0381731 0.622602 0.172566 0.383546 0.380131 0.22C0.570131 0.08 0.780131 0 1.00013 0H15.0001C15.2201 0 15.4301 0.08 15.6201 0.22C15.8277 0.383546 15.9621 0.622602 15.9939 0.88493C16.0258 1.14726 15.9525 1.41153 15.7901 1.62L10.0301 9H10.0001Z"
            fill="#656BFF"
          />
        </svg>
      </div>

      <div class="DropdownMenu" v-show="showMobileMenu">
        <div class="FiltersBlock noselect">
          <div class="StatusesBlock">
            <span
              v-for="(s, sindx) in filtersButtons"
              :class="{
                selected: s.val === FilterSelected,
              }"
              :key="s + sindx"
              @click="
                if (FilterSelected == s.val) {
                  FilterSelected = null;
                } else {
                  FilterSelected = s.val;

                  postFiltersDataChanges({
                    button: FilterSelected,
                    search: SearchText,
                    sorting: SortSelected,
                  });

                  if (filterButtonModeServer) {
                    callbackForFilterButton(s.val);
                  } else {
                    sortAndFilterArray();
                  }
                }
              "
              >{{ s.text }}</span
            >
          </div>
          <div class="SortBy">
            <select
              v-if="sortOptions?.length > 1"
              class="SelectInput"
              v-model="SortSelected"
              @change="
                () => {
                  postFiltersDataChanges({
                    button: FilterSelected,
                    search: SearchText,
                    sorting: SortSelected,
                  });
                  sortAndFilterArray();
                }
              "
            >
              <option
                v-for="(f, findx) in sortOptions"
                :key="f.text + findx"
                :value="f.val"
                >{{ f.text }}</option
              >
            </select>

            <span
              class="ResetFilters"
              @click="
                () => {
                  resetFilters();
                }
              "
              >Сбросить фильтры</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Fuse from "fuse.js";

export default {
  /*
    !Во-избежание проблем с отрисовкой / устаревшими данными
    !На данный компонент следует добавить аттрибут :key
    !Например вот так :key="'SortingFiltersSearch-' + products_hash"
    !products_hash - computed метод, который возвращает строку уникальных id из originalArray
    !в методах этого компонента оставил пример реализации (закомментированным)
  */
  name: "SortWithFiltersPreset",
  components: {},
  props: {
    /*
        Скрывает кнопку фильтра
       */
    onlySearch: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      /*
        ^Плейсхолдер для строки поиска

        ex.: "Text"
       */
      type: String,
      default: "Поиск по каталогу",
    },
    searchAttribures: {
      /*
        ^аттрибуты по которым производится поиск

        ex.: ["name", "desc", "price"]
       */
      type: Array,
      default: () => [],
    },
    originalArray: {
      /*
        ^Массив по которому производится поиск

        ex.: [{text: "123", id: 1....]
       */
      type: Array,
      default: () => [],
      required: true,
    },
    setNewArrayCallback: {
      /*
        ^Функция обратного вызова, для возврата сортированного и/или фильтрованного массива

        ex.: см. client\src\views\Profile\Octastore.vue функция "handleSearchFilterSortingObject"
       */
      type: Function,
      default: () => {},
      required: true,
    },
    sortMethodCallback: {
      /*
        ^Вызываем функцию сортировки на родителе по названию сортировки

        ex.: см. client\src\views\Profile\Octastore.vue функция "sortingMethods"
       */
      type: Function,
      default: () => {},
      required: true,
    },
    filtersButtons: {
      /*
        ^Кнопки быстрых фильтров, которые отображаются

        ex.: [{text: "Доступные"}, {text: "Новинки"}]
       */
      type: Array,
      default: () => [],
      required: true,
    },
    sortOptions: {
      /*
        ^Массив с выбором сортировки
        ?Выбор сортировки будет скрыт, если длина этого массива будет == 1

        ex.: [{ text: "Сортировка по умолчанию", val: "default", }].
        Родителю будет передан текст из поля val
        см. client\src\views\Profile\Octastore.vue функция "sortingMethods"
       */
      type: Array,
      default: () => [],
      required: true,
    },
    title: {
      /*
      ^Заголовок компонента

      ex.: "Text"
      */
      type: String,
      default: "",
      required: true,
    },

    filterButtonModeServer: {
      /*
        !Если filterButtonModeServer === true:
        *Используем callbackForFilterButton

        !Если filterButtonModeServer === false:
        *Используем filterButtonParentFilter

        ^От этого зависит, какой метод мы будем использовать
       */

      type: Boolean,
      default: false,
    },
    /*
      !ЛИБО мы используем действие фильтрации на родителе (эта функция),
      !ЛИБО мы используем filterButtonParentFilter
    */
    callbackForFilterButton: {
      /*
        ^Отправляем значение "val" кнопки фильтров (Текстовое значение)
        *Родитель должен передать В ЭТОТ КОМПОНЕНТ фильтрованный массив

        ?Рекомендуется использовать, если есть возможность фильтрация на бэке

         ex.: см. client\src\views\Profile\Octastore.vue функция "handleFilterButton"
       */
      type: Function,
      default: () => {},
    },
    filterButtonParentFilter: {
      /*
        ^Отправляем значение "val" кнопки фильтров (Текстовое значение) и массив
        *Родитель должен ВЕРНУТЬ фильтрованный массив

        ?Рекомендуется использовать, если не требуется фильтрация на бэке
       */
      type: Function,
      default: () => [],
    },

    /*
      !Нужно использовать совместно postFiltersDataChanges и setFiltersData
      !Первая функция синхронизирует переменные с родителем
      !Вторая функция при перерисовке страницы позволяет сохранить выбранные параметры,
      !   чтобы они не сбрасывались при нажатии на кнопку
    */
    postFiltersDataChanges: {
      /*
        ^Отправляем данные фильтров & поиска & сортировки в родителя,
        ^   чтобы при перерисовке компонента не происходило сброса параметров

        ?Отправляет в родителя объект, следующего вида:
        ?   {button: FilterSelected, search: SearchText, sorting: SortSelected}
       */
      type: Function,
      default: () => {},
      required: true,
    },
    setFiltersData: {
      /*
        ^Получаем заранее установленные данные для фильтров/поиска/сортировки
        !Позволяет использовать выбранные параметры из родителя при перерисовке страницы

        ?Получаем от родителя объект следующего вида:
        ?   {button: FilterSelected, search: SearchText, sorting: SortSelected}
      */

      type: Object,
      default: null,
    },

    /*
      Если нам нужно произвести какое-то доп. действие с кнопками по правому клику
      Возвращает val значение кнопки
    */
    handleRightClickOnButton: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      SearchText: "",
      FilterSelected: null,
      SortSelected: "default",

      showMobileMenu: false,
    };
  },
  created() {
    if (this.setFiltersData != null) {
      if (this.setFiltersData?.search) {
        this.SearchText = this.setFiltersData?.search;
      }
      if (this.setFiltersData?.button) {
        this.FilterSelected = this.setFiltersData?.button;
      }
      if (this.setFiltersData?.sorting) {
        this.SortSelected = this.setFiltersData?.sorting;
      }
    }

    this.sortAndFilterArray();
  },
  methods: {
    sortAndFilterArray() {
      let data = [...this.originalArray];
      let result = [];

      if (!this.filterButtonModeServer) {
        data = this.filterButtonParentFilter(this.FilterSelected, data);
      }

      if (this.SearchText === "" && this.SortSelected === "default") {
        this.setNewArrayCallback(data);
        return 0;
      }

      if (this.SearchText !== "") {
        // Опции для настройки поиска
        const options = {
          isCaseSensitive: false,
          includeScore: false,
          shouldSort: false,
          threshold: 0.25,
          keys: this.searchAttribures, // Поля, по которым будет производиться поиск
        };

        // Инициализация экземпляра fuse
        const fuse = new Fuse(data, options);

        // Выполнение поиска
        result = fuse.search(this.SearchText);
        result = result.map((x) => x.item);
      }

      if (this.SortSelected !== "default") {
        if (this.SearchText === "") {
          result = [...data];
        }
        result = this.sortMethodCallback(this.SortSelected, result);
      }

      // Вывод результатов
      this.setNewArrayCallback(result);
    },

    resetFilters() {
      this.SearchText = "";
      this.FilterSelected = null;
      this.SortSelected = "default";

      this.postFiltersDataChanges({
        button: this.FilterSelected,
        search: this.SearchText,
        sorting: this.SortSelected,
      });

      if (this.filterButtonModeServer) {
        this.callbackForFilterButton(this.FilterSelected);
      }

      this.sortAndFilterArray();
    },

    /*
    products_hash() {
      let a = this.store.products.map((x) => x.id);
      return a.join("-");
    },
    */
  },
};
</script>

<style scoped>
.MobileFilterBlock {
  display: none;
}
.OnlySearchMode .MobileFilterBlock {
  display: none;
}
.OnlySearchMode .ResetFilters {
  display: none;
}
.OnlySearchMode .Filters {
  justify-content: flex-start;
  gap: 0;
}

.OnlySearchMode .MobileFilterBlock {
  display: none;
}
.OnlySearchMode .ResetFilters {
  display: none;
}
.OnlySearchMode .Filters {
  justify-content: flex-start;
  gap: 0;
}

.SortWithFiltersPreset {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 36px;

  width: 100%;
  height: fit-content;
}

.FiltersHeader {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #ffffff;
}

.Filters {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: space-between;
  gap: 40px;

  width: 100%;
  height: fit-content;
}
.FiltersBlock {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  width: auto;
  height: fit-content;
}

.SearchInput {
  position: relative;
  display: block;

  padding: 12px 16px;

  width: 378px;
  height: fit-content;

  border-radius: 4px;
  border: 1px solid #d3caff40;

  background-color: transparent;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: left;

  transition: all 0.2s ease-out;

  color: #fff;

  outline: none;
}
.SearchInput:hover {
  border: 1px solid #656bff80;
}
.SearchInput:focus {
  border: 1px solid #656bff;
}

.SearchInput::placeholder,
.SearchInput::-ms-input-placeholder {
  color: #d3caff73;
}

.StatusesBlock,
.SortBy {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  width: auto;
  height: fit-content;
}
.StatusesBlock > span {
  position: relative;
  display: block;

  cursor: pointer;

  padding: 12px 16px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  border: 1px solid #656bff;
  background-color: transparent;
  transition: all 0.2s ease-out;
}
.StatusesBlock > span.selected,
.StatusesBlock > span:hover {
  background-color: #656bff;
  border: 1px solid transparent;
}

.ResetFilters {
  position: relative;
  display: inline-block;

  cursor: pointer;

  padding: 0.5em 1em 0.5em 1em;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  border: 1px solid transparent;
  background-color: #656bff;
  transition: all 0.2s ease-out;
}
.ResetFilters:hover {
  background-color: transparent;
  border: 1px solid #656bff;
}

.SelectInput {
  position: relative;
  display: inline-block;

  cursor: pointer;

  padding: 0.5em 3.5em 0.5em 1em;
  padding-right: 45px;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;

  border-radius: 4px;
  border: 1px solid #656bff;
  background-color: transparent;
  transition: all 0.2s ease-out;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    radial-gradient(#ddd 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
}
.SelectInput::-ms-expand {
  display: none;
}
.SelectInput:focus {
  background-image: linear-gradient(45deg, white 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, white 50%),
    radial-gradient(gray 70%, transparent 72%);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  outline: 0;
}
.SelectInput:hover {
  background-color: #656bff;
  border: 1px solid transparent;
}
.SelectInput option {
  background-color: #656bff;
}
</style>

<style scoped>
@media (max-width: 480px) {
  .FiltersHeader {
    font-size: 18px;
    line-height: normal;
  }

  .Filters {
    padding-left: 4px;
    gap: 8px;
  }

  .FiltersBlock {
    display: none;
  }
  .SearchInput {
    width: 100%;
    font-size: 13px;
  }

  .MobileFilterBlock {
    position: relative;
    display: flex;

    width: 50px;
    height: 50px;

    background-color: #656bff40;
    border-radius: 4px;

    flex-shrink: 0;

    transition-timing-function: ease-in;
    transition: 0.2s;
  }
  .MobileFilterBlock:hover {
    background-color: rgba(101, 107, 255, 0.2);
  }
  .MobileFilterBlock > svg {
    margin: auto;
  }

  .DropdownMenu {
    position: absolute;
    display: flex;

    flex-direction: column;
    justify-content: flex-start;

    padding: 20px;

    width: 100%;
    height: fit-content;

    transform: translate(0%, 60px);
    z-index: 199;

    transition-timing-function: ease-in;
    transition: 0.2s;

    backdrop-filter: blur(10px);
    border-radius: 8px;

    background-color: #00000085;
    border: 1px solid #292b6f;
  }

  .DropdownMenu > .FiltersBlock {
    display: flex;
  }

  .StatusesBlock {
    gap: 4px;
  }

  .StatusesBlock > span {
    font-size: 13px;
    padding: 4px 8px;
  }

  .DropdownMenu .SortBy {
    margin-top: 20px;
  }

  .DropdownMenu .SelectInput {
    font-size: 13px;
    width: 100%;

    background-position: calc(100% - 16px) calc(1em + 2px),
      calc(100% - 11px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  }

  .DropdownMenu .SelectInput:focus {
    background-position: calc(100% - 11px) 1em, calc(100% - 16px) 1em,
      calc(100% - 0.5em) 0.5em;
  }

  .DropdownMenu .ResetFilters {
    font-size: 13px;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .FiltersHeader {
    font-size: 24px;
  }

  .Filters {
    padding-left: 4px;
    gap: 8px;
  }

  .FiltersBlock {
    display: none;
  }
  .SearchInput {
    width: 100%;
  }

  .MobileFilterBlock {
    position: relative;
    display: flex;

    width: 50px;
    height: 50px;

    background-color: #656bff40;
    border-radius: 4px;

    flex-shrink: 0;

    transition-timing-function: ease-in;
    transition: 0.2s;
  }
  .MobileFilterBlock:hover {
    background-color: rgba(101, 107, 255, 0.2);
  }
  .MobileFilterBlock > svg {
    margin: auto;
  }

  .DropdownMenu {
    position: absolute;
    display: flex;

    flex-direction: column;
    justify-content: flex-start;

    padding: 20px;

    width: 100%;
    height: fit-content;

    transform: translate(0%, 60px);
    z-index: 199;

    transition-timing-function: ease-in;
    transition: 0.2s;

    backdrop-filter: blur(10px);
    border-radius: 8px;

    background-color: #00000085;
    border: 1px solid #292b6f;
  }

  .DropdownMenu > .FiltersBlock {
    display: flex;
  }
}
</style>
