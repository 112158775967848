import { createRouter, createWebHistory } from "vue-router";
import { logout } from "@/api/auth.js";

import NewLanding from "@/views/Landing";
import NewLandingEnterprise from "@/views/LandingForOrganizations";
// import NewLandingGOS from "@/views/LandingForGOS";

import AboutPage from "@/views/About";
import VKRPage from "@/views/VKRPage";

import betaRouter from "./modules/beta";
import profileRouter from "./modules/profile";
import authRouter from "./modules/auth";
import competenceRouter from "./modules/competence";
import eduRouter from "./modules/edu";
import projectRouter from "./modules/project";
import organizationRouter from "./modules/organization";
import adminRouter from "./modules/admin";

import contentRouter from "./modules/content.js";
import registrationRouter from "./modules/registration.js";

import store from "../store/index.js";

const routes = [
  {
    path: "/",
    component: NewLanding,
  },
  {
    path: "/business",
    component: NewLandingEnterprise,
  },
  {
    path: "/about",
    component: AboutPage,
  },
  {
    path: "/vkr",
    component: VKRPage,
  },
  authRouter,
  profileRouter,
  competenceRouter,
  eduRouter,
  projectRouter,
  adminRouter,
  organizationRouter,
  betaRouter,
  contentRouter,
  registrationRouter,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const user = store?.state?.auth?.user;
  const role = user?.role?.name;
  const hasParentCompany = user?.parent_company?.length > 0;
  const hasUserPinTask = user?.UserPinTask?.length > 0;

  const isAdmin = () => role === "admin";
  const handleLogout = () => {
    localStorage.removeItem("vuex");
    next("/");
  };

  // Обработка маршрута, требующего пользователя
  if (to.matched.some((record) => record.meta.user)) {
    // Выход, если нет роли
    if (!role) {
      return handleLogout();
    }

    // Редирект для админа
    if (isAdmin()) {
      return next("/admin/checkTask");
    }

    // Редирект на профиль организации при наличии parent_company
    if (hasParentCompany) {
      return next("/org/profile");
    }

    // Продолжаем, если есть задачи
    // if (hasUserPinTask) {
    //   return next();
    // }

    // if (
    //   [
    //     "ContentProjectsList",
    //     "ContentProjectTasks",
    //     "CGChatPage",
    //     "CGResultPage",
    //   ].includes(to.name)
    // ) {
    //   return next();
    // }

    // return next("/registration/user-info");
    return next();
  }

  // Если маршрут требует администратора
  if (to.matched.some((record) => record.meta.admin)) {
    if (!role) {
      return handleLogout();
    }

    if (isAdmin()) {
      return next();
    }

    return next("/");
  }

  // Если маршрут требует аккаунта организации
  if (to.matched.some((record) => record.meta.user_org)) {
    if (!role) {
      return handleLogout();
    }

    if (hasParentCompany) {
      return next();
    }

    return next("/");
  }

  // Если маршрут не требует специальных проверок
  next();
});

export default router;
