import EmptyLayout from "@/layout/EmptyLayer";

const BetaRouter = {
  path: "/registration",
  name: "Registration",
  redirect: "/registration/user-info",
  component: EmptyLayout,
  children: [
    {
      path: "user-info",
      name: "RegistrationUserInfo",
      component: () => import("@/views/BetaWelcomePage.vue"),
      meta: {
        user: true,
      },
    },
  ],
};

export default BetaRouter;
