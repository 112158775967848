import EmptyLayout from "@/layout/EmptyLayer";

const projectRouter = {
  path: "/org",
  name: "Organization",
  redirect: "/org/profile",
  component: EmptyLayout,
  children: [
    {
      path: "profile",
      name: "OrgProfile",
      component: () => import("@/views/Organization/Home.vue"),
      meta: {
        user_org: true,
      },
    },
    {
      path: "partner/invites",
      name: "OrgPartnerInvites",
      component: () => import("@/views/Organization/OrgInviteLinks.vue"),
      meta: {
        user_org: true,
      },
    },
    {
      path: `partners/list`,
      name: "OrgPartnersList",
      component: () => import("@/views/Organization/OrgPartners.vue"),
      meta: {
        user_org: true,
      },
    },
    {
      path: `invited`,
      name: "OrgInviteRegistration",
      component: () => import("@/views/Auth/UniqueLinkRegistration.vue"),
    },
    {
      path: `students-list`,
      name: "OrgGetStudentsList",
      component: () => import("@/views/Organization/getStudentsList.vue"),
      meta: {
        user_org: true,
      },
    },
    {
      path: `vuz-students`,
      name: "OrgGetStudentsListForVUZ",
      component: () => import("@/views/Organization/getStudentsListForVUZ.vue"),
      meta: {
        user_org: true,
      },
    },
    {
      path: `students-rating`,
      name: "OrgGetStudentsRating",
      component: () => import("@/views/Organization/RatingStudent.vue"),
      meta: {
        user_org: true,
      },
    },
  ],
};

export default projectRouter;
